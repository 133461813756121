<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <el-card class="page-card">
      <!-- <slot name="header" class="clearfix">
        <el-button type="primary" @click="add" v-if="max === 0 || count < max"
          >{{ $t("common.add") }} {{ $t(name) }}</el-button
        >
        <el-descriptions v-else :title="$t(name)"></el-descriptions>
      </slot> -->
      <sui-list
        ref="list"
        :column="tableColumn"
        :fun="tableFun"
        :req="tableFunReq"
        @backflow="backflow"
      />
      <!-- <el-drawer
        :title="`${$t(formData.id ? 'common.edit' : 'common.add')} ${$t(name)}`"
        :visible="editing"
        size="500px"
        close-on-press-escape
        @close="close"
      >
        <sui-form
          ref="form"
          :list="formList"
          :defaultdata="formData"
          @submit="submit"
        />
      </el-drawer> -->
      <!-- </el-dialog> -->
    </el-card>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import SuiForm from "@/components/s-ui/form";
import SuiList from "@/components/s-ui/list";
import {
  // AddExchateRate,
  GetExchateRateList,
  // EditExchateRate,
  // DelExchateRate,
} from "@/utils/api";
import { formatDataTime } from "@/utils";

// const defaultFormData = {
//   currencyFrom: "",
//   rate: "",
// };
export default {
  components: {
    //SuiForm,
    SuiList,
    PageHeader,
    Layout,
  },
  props: {
    max: {
      type: Number,
      value: 0,
    },
  },
  data() {
    return {
      title: this.$t("content.exchaterate.text"),
      items: [
        {
          text: this.$t("content.exchaterate.text"),
        },
        {
          text: this.$t("content.exchaterate.lists"),
          active: true,
        },
      ],
      // editing: false,
      // formList: [
      //   {
      //     type: "input",
      //     name: "ID",
      //     hidden: true,
      //   },
      //   {
      //     type: "select",
      //     name: "currencyFrom",
      //     label: "content.exchaterate.from",
      //     range: [
      //       {
      //         label: "content.exchaterate.CNY",
      //         value: "CNY",
      //       },
      //       {
      //         label: "content.exchaterate.JPY",
      //         value: "JPY",
      //       },
      //       {
      //         label: "content.exchaterate.GBP",
      //         value: "GBP",
      //       },
      //       {
      //         label: "content.exchaterate.HKD",
      //         value: "HKD",
      //       },
      //       {
      //         label: "content.exchaterate.CAD",
      //         value: "CAD",
      //       },
      //       {
      //         label: "content.exchaterate.EUR",
      //         value: "EUR",
      //       },
      //       {
      //         label: "content.exchaterate.KRW",
      //         value: "KRW",
      //       },

      //       {
      //         label: "content.exchaterate.AUD",
      //         value: "AUD",
      //       },
      //     ],
      //     rules: [
      //       {
      //         required: true,
      //         message: "content.exchaterate.fromReq",
      //         trigger: "blur",
      //       },
      //     ],
      //   },
      //   {
      //     type: "number",
      //     name: "rate",
      //     label: "content.exchaterate.rate",
      //     rules: [
      //       {
      //         required: true,
      //         message: "content.exchaterate.rateReq",
      //         trigger: "blur",
      //       },
      //     ],
      //   },
      // ],
      // formData: {},
      tableColumn: [
        {
          name: "currencyFrom",
          label: "content.exchaterate.from",
        },
        {
          name: "rate",
          label: "content.exchaterate.rate",
        },
        {
          name: "currencyTo",
          label: "content.exchaterate.currencyTo",
        },
        {
          name: "createAt",
          label: "content.exchaterate.createAt",
          formatFn: formatDataTime,
          width: 110,
        },

        {
          name: "updateAt",
          label: "content.exchaterate.updateAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      tableFun: GetExchateRateList,
      tableFunReq: {
        orderKey: "",
        pageSize: this.max || 10,
      },
      count: 0,
    };
  },
  methods: {
    backflow(count) {
      this.count = count;
    },
    // add() {
    //   this.editing = true;
    //   const formData = {
    //     type: this.type,
    //     ...defaultFormData,
    //   };
    //   this.formData = formData;
    //   this.$nextTick(() => {
    //     this.formData = formData;
    //   });
    // },
    // edit(rowData) {
    //   console.log("edit-rowData:", rowData);
    //   this.editing = true;
    //   this.$nextTick(() => {
    //     this.$refs["form"].setForm(rowData);
    //   });
    // },
    // del(rowData) {
    //   console.log("del-rowData:", rowData);
    //   DelExchateRate({
    //     id: rowData.id,
    //   })
    //     .then((res) => {
    //       console.log("del-res:", res);
    //       this.$message({
    //         type: "success",
    //         message: this.$t("common.deleteSuccess"),
    //       });
    //       this.$refs["list"].getList();
    //     })
    //     .catch((err) => {
    //       console.log("del-err:", err);
    //     });
    // },
    // iEditBanner(id, params) {
    //   return EditExchateRate({
    //     id,
    //     ...params,
    //   });
    // },
    // submit(data) {
    //   console.log("submit:", data);
    //   const fn = data.id ? EditExchateRate : AddExchateRate;
    //   fn(data)
    //     .then((res) => {
    //       console.log("add-res:", res);
    //       this.$message({
    //         type: "success",
    //         message: this.$t("common.optSuccess"),
    //       });
    //       this.editing = false;
    //       this.$refs["list"].getList();
    //     })
    //     .catch((err) => {
    //       console.log("add-err:", err);
    //     });
    // },
    // close() {
    //   this.editing = false;
    //   this.formData = {};
    //   this.$refs["form"].resetFields();
    // },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
    .el-icon-upload {
      margin-top: 15px;
    }
  }
}
</style>